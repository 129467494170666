.title__container {

}
.title {
  @apply flex items-center space-x-2;
}
.title--medium {
  @apply font-medium;
}
.title--semibold {
  @apply font-semibold;
}
.title--bold {
  @apply font-bold;
}
.title--extrabold {
  @apply font-extrabold;
}
.title--black {
  @apply font-black;
}
.title__text {
  @apply w-full;
}