.utf__container {
  @apply flex w-full flex-col;
}
.utf__input__field {
  @apply border-b py-1 focus:outline-none focus:border-etpi-blue w-full font-bold transition duration-150;
}
.utf__value {
  @apply w-full py-1 border-b cursor-text flex ;
}
.utf__input__field--left, .utf__value--left {
  @apply text-left justify-start;
}
.utf__input__field--center, .utf__value--center {
  @apply text-center justify-center;
}
.utf__input__field--right, .utf__value--right {
  @apply text-right justify-end;
}