.small-box {
  @apply rounded-md px-4 py-3;
}

.small-box--blue {
  @apply bg-etpi-blue-100 text-etpi-blue-600;
}
.small-box--yellow {
  @apply bg-etpi-yellow-100 text-etpi-yellow-500;
}
.small-box--green {
  @apply bg-green-100 text-etpi-green-700;
}
.small-box--red {
  @apply bg-red-100 text-etpi-red-600;
}