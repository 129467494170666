@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    scroll-behavior: smooth;
  }
  body {
    font-family: 'Inter', sans-serif;
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  /* width */
 

  /* Track */
  ::-webkit-scrollbar-track {
    background: #fafafa;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    @apply rounded-full bg-slate-300;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    @apply bg-slate-400;
  }
}

@layer components {
  .act-btn {
    @apply w-11 h-11 text-gray-400 transition duration-150 border border-gray-400 rounded-md cursor-pointer hover:bg-gray-100;
  }
  .act-btn--blue {
    @apply hover:text-etpi-blue hover:border-etpi-blue;
  }
  .act-btn--red {
    @apply hover:text-etpi-red hover:border-etpi-red;
  }

  .link {
    @apply cursor-pointer transition duration-150;
  }
  .link--to-primary:hover, .link--primary--active {
    @apply text-etpi-blue;
  }
  .link--to-secondary:hover, .link--secondary--active {
    @apply text-etpi-orange;
  }
}
