.list-card {
  @apply flex flex-col flex-1 overflow-hidden;
}
.list-card__header {
  @apply text-base ;
}
.list-card__header--gray {
  @apply bg-slate-400 text-white;
}
.list-card__header--green {
  @apply bg-etpi-green-700 text-white;
}
.list-card__content {
  @apply relative flex-1 flex flex-col;
}

