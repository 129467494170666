.chip {
  @apply flex items-center justify-center transition duration-200 rounded-full space-x-2 cursor-pointer focus:outline-none;
}
.chip--outline {
  @apply border;
}

.chip--sm {
  @apply text-xs p-2 flex items-center justify-center; 
}
.chip--md {
  @apply text-sm p-3 flex items-center justify-center; 
}
.chip--lg {
  @apply text-base p-4;
}

.chip--blue {
  @apply bg-etpi-blue-100 border-etpi-blue text-etpi-blue hover:bg-etpi-blue-200;
}
.chip--red {
  @apply bg-etpi-red-100 border-etpi-red text-etpi-red hover:bg-etpi-red-200;
}
.chip--orange {
  @apply bg-etpi-orange-100 border-etpi-orange text-etpi-orange hover:bg-etpi-orange-200;
}
.chip--gray {
  @apply bg-etpi-gray-100 border-etpi-gray text-etpi-gray hover:bg-etpi-gray-200;
}
.chip--disabled {
  @apply bg-etpi-gray-100 border-etpi-gray-100 text-etpi-gray-300 cursor-not-allowed;
}



