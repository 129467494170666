.actions {
    position: relative;
}
.actions .btn--red {
    position: absolute;
    right: 0;
    bottom: 0;
}

.actions .btn--disabled {
    position: absolute;
    right: 0;
    bottom: 0;
}

.actions .btn--disabled .loading {
    border: 2px solid;
    border-style: inset;
}

.actions .loading {
    border: 2px solid white;
    border-radius: 259px;
    border-style: inset;
}
