.global-layout {
  @apply flex h-screen w-full;
}
.global-layout__content {
  @apply h-full flex-1  p-5 w-full bg-etpi-blue-50 bg-opacity-50;
}
.global-layout__content--breadcrumbs {
  @apply pt-16;
}
.sidebar {
  @apply w-16 h-full bg-white flex flex-col;
}
.sidebar__icon {
  @apply h-20 flex items-center justify-center flex-col;
}
.sidebar__menus {
  @apply flex flex-col h-full;
}
.sidebar__menu {
  @apply text-etpi-blue border-r-4 border-transparent hover:text-etpi-orange hover:bg-etpi-orange-50 transition duration-150 cursor-pointer;
}
.sidebar__menu.active {
  @apply text-etpi-orange border-etpi-orange;
}
.sidebar__menu__icon {
  @apply text-2xl h-16 w-16;
}
