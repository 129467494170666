.btn {
  @apply flex items-center justify-center border transition duration-200 rounded-md space-x-2 font-medium;
}

.btn--sm {
  @apply text-xs py-1.5 px-2.5 flex items-center justify-center; 
}
.btn--md {
  @apply text-sm py-2 px-3 flex items-center justify-center; 
}
.btn--lg {
  @apply text-base py-2.5 px-4;
}

.btn--blue {
  @apply bg-etpi-blue border-etpi-blue text-white hover:bg-etpi-blue-500;
}
.btn--blue.btn--outline, .btn--blue.btn--round-outline {
  @apply bg-transparent border-etpi-blue text-etpi-blue hover:bg-etpi-blue-100 ;
}
.btn--red {
  @apply bg-etpi-red border-etpi-red text-white hover:bg-etpi-red-500;
}
.btn--red.btn--outline, .btn--red.btn--round-outline{
  @apply bg-transparent border-etpi-red text-etpi-red  hover:bg-etpi-red-100 ;
}
.btn--orange {
  @apply bg-etpi-orange border-etpi-orange text-white hover:bg-etpi-orange-400;
}
.btn--orange.btn--outline, .btn--orange.btn--round-outline{
  @apply bg-transparent border-etpi-orange text-etpi-orange  hover:bg-etpi-orange-100 ;
}
.btn--gray {
  @apply bg-slate-500 border-slate-500 text-white hover:bg-slate-600 hover:border-slate-600;
}
.btn--gray.btn--outline, .btn--gray.btn--round-outline {
  @apply bg-transparent border-slate-500 text-slate-500 hover:bg-slate-100 ;
}

.btn--round-outline {
  @apply rounded-full p-3;
}

.btn--disabled {
  @apply bg-slate-100 border-etpi-gray-100 text-etpi-gray-300 cursor-not-allowed ;
}

