.msg-box {
  @apply flex transition duration-200 rounded-md p-3;
}

.msg-box--blue {
  @apply bg-transparent text-etpi-blue bg-etpi-blue-100;
}
.msg-box--orange {
  @apply bg-transparent text-etpi-orange bg-etpi-orange-100;
}
.msg-box--red {
  @apply bg-transparent text-etpi-red bg-etpi-red-100;
}
.msg-box--yellow {
  @apply bg-transparent text-etpi-yellow-600 bg-etpi-yellow-100;
}
.msg-box--green {
  @apply bg-transparent text-etpi-green bg-etpi-green-100;
}
.msg-box--gray {
  @apply bg-transparent text-etpi-gray bg-etpi-gray-50;
}