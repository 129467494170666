.input__container {
  @apply flex flex-col space-y-1 w-full relative;
}
.input__label {
  @apply text-xs font-semibold;
}
.input__items {
  @apply relative flex items-center;
}
.input__field {
  @apply px-3 h-11 border rounded-md focus:outline-none  transition duration-150 w-full text-sm;
}
.input__field:focus, .input__field--active {
  @apply border-etpi-blue-600;
}
.input__field[type='button'] {
  @apply cursor-pointer;
}
.input__field--error {
  @apply border-etpi-red-600 bg-etpi-red-100;
}
.input__field--right-adornment {
  @apply pr-9;
}
.input__field--left-adornment {
  @apply pl-9;
}
.input__dropdown {
  @apply absolute top-11 border z-20 w-full max-h-36 bg-white overflow-y-auto shadow-lg;
}
.input__dropdown__option {
  @apply py-2 px-3 hover:bg-slate-50 cursor-pointer text-sm;
}
.input__adornment {
  @apply absolute transition duration-150 text-sm ;
}
.input__adornment--right {
  @apply right-3;
}
.input__adornment--left {
  @apply left-3;
}
.input__adornment--rotate {
  @apply -rotate-180;
}