.notification {
    @apply fixed z-[31] right-8 top-8 py-3 pl-5 pr-3 rounded-md w-[21%];
}
.notification--blue {
    @apply bg-etpi-blue;
}
.notification--red {
    @apply bg-etpi-red;
}
.notification__header {
    @apply flex justify-between;
}
.notification__header__icon {
    @apply text-3xl text-white cursor-pointer;
}
.notification__header p {
    font-family: system-ui;
    @apply text-white font-bold text-base;
}
.notification__contents p {
    font-family: system-ui;
    @apply text-white text-base;
}
