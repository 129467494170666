.alert__container {
  @apply fixed z-50 inset-0 flex flex-col items-center justify-center p-5;
}
.alert {
  @apply  bg-white w-full rounded-md z-50 relative;
}
.alert--sm {
  @apply max-w-sm;
}
.alert--md {
  @apply max-w-md;
}
.alert--lg {
  @apply max-w-lg;
}
.alert--xl {
  @apply max-w-xl;
}
.alert--2xl {
  @apply max-w-2xl;
}
.alert--3xl {
  @apply max-w-3xl;
}
.alert--4xl {
  @apply max-w-4xl;
}
.alert--5xl {
  @apply max-w-5xl;
}
.alert--6xl {
  @apply max-w-6xl;
}
.alert--7xl {
  @apply max-w-7xl;
}
.alert__header {
  @apply px-5 py-4 flex w-full rounded-t-md font-semibold;
}
.alert__header--blue {
  @apply bg-etpi-blue text-white;
}
.alert__header__close {
  @apply text-2xl ml-auto cursor-pointer;
}
.alert__close {
  @apply text-2xl ml-auto cursor-pointer absolute right-4 top-4;
}